import React from "react"
import makeCarousel from "react-reveal/makeCarousel"
import { Container, Children, Dot, Dots } from "./styles"

const CarouselUI = ({ position, total, handleClick, children }) => (
  <Container>
    <Children>{children}</Children>
    <Dots>
      {Array(...Array(total)).map((val, index) => (
        <Dot key={index} onClick={handleClick} data-position={index}>
          {index === position ? "● " : "○ "}
        </Dot>
      ))}
    </Dots>
  </Container>
)

const CarouselContainer = makeCarousel(CarouselUI)

export const Carousel = ({ children }) => {
  return <CarouselContainer>{children}</CarouselContainer>
}
