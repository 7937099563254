import tw, { styled } from "twin.macro"

export const Container = tw.div`
  sm:w-1/2
  w-2/3
  mx-auto
  h-48
  mt-10
  relative
  text-center
  flex
  justify-center
  items-center
  flex-col
`

export const Children = tw.div`
  w-full
  relative
  h-3/4
`

export const Dot = tw.span`
  text-xs
  cursor-pointer
  text-gray-300
`
export const Dots = tw.div`
  flex
  items-center
  justify-center
  space-x-1
  text-center
  relative
  w-56
  z-10
  h-1/4
  hidden
  sm:block
`
