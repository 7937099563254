import { Link } from "gatsby"
import React from "react"
import Slide from "react-reveal/Slide"
import screenshot from "../../assets/pricesettingScreenShot.png"
import CallToActionPageBottom from "../../components/CallToActionPageBottom"
import { Carousel } from "../../components/Carousel"
import Layout from "../../components/Layout"
import { PageHeading } from "../../components/PageHeading"
import { teams } from "../../data/teams"
const slug = require("slug")

export default function WhyPricelist() {
  return (
    <>
      <Layout title="Why Pricelist | Pricelist">
        <div class="relative px-4 sm:px-6 lg:px-8 mt-16">
          <div class="text-lg mx-auto">
            <div className="w-4/5 sm:w-1/2 mx-auto">
              <PageHeading
                title="Pricelist optimizes, simplifies, and automates your pricing strategy"
                subtitle="Why Pricelist"
              />
            </div>

            <div className="mt-6 prose prose-indigo prose-base sm:prose-lg text-gray-500 mx-auto">
              <p>
                Pricelists combines state-of-the-art technology and a deep
                pricing expertise to make you more succesful. We've built it to
                make your entire product life cycle &mdash; from a launch to
                end-of-life &mdash; smoother and more efficient.
              </p>
              <p>
                Modern teams accomplish the most when they work together. But
                many go-to-market processes remain siloed. Pricelist breaks down
                these silos and empowers team members to focus on impact, not
                rote calculations or repeated data gathering.
              </p>
              <p>When you use Pricelist, you:</p>
              <ul className="sm:text-base text-sm">
                <li>
                  Reduce time-to-price from weeks to minutes thanks to its smart
                  pricing algorithms
                </li>
                <li>
                  Eliminate last-minute surprises and chasing of sign-offs
                  through a built-in approval tool
                </li>
                <li>
                  Keep a finger on the pulse of your market through automated
                  price alerts and news updates
                </li>
                <li>
                  Get access to countless{" "}
                  <Link to="/teams/">
                    other benefits for every member of your team
                  </Link>
                </li>
              </ul>

              <p className="text-center font-bold text-gray-900 text-xl pt-10">
                This is how easy it is to use Pricelist:
              </p>
            </div>

            <img
              className="w-2/3 lg:rounded-md shadow-xl ring-1 ring-black ring-opacity-5 mx-auto sm:my-16 my-10"
              src={screenshot}
              alt=""
            />

            <p className="text-center font-bold text-gray-900 text-xl">
              Explore Pricelist's other benefits:
            </p>

            <Carousel>
              {teams &&
                teams.map(({ name, benefits }) => (
                  <Slide right>
                    <div className="font-light">
                      <div>
                        <Link
                          to={`/teams/${slug(name)}`}
                          className="text-xs text-indigo-800 bg-indigo-50 rounded-lg w-auto my-2 py-1 px-3 font-semibold"
                        >
                          {name}
                        </Link>
                      </div>
                      <h3 className="text-gray-800 py-4 text-2xl">
                        {benefits[0].heading}
                      </h3>
                      <p className="text-gray-600 tracking-wide text-sm sm:text-base">
                        {benefits[0].description}
                      </p>
                    </div>
                  </Slide>
                ))}
            </Carousel>
          </div>

          <CallToActionPageBottom />
        </div>
      </Layout>
    </>
  )
}
